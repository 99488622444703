@import url('https://fonts.googleapis.com/css2?family=Bayon&family=Kantumruy:wght@300&family=Noto+Serif+Khmer:wght@100;800&display=swap');



.print-body {
    /* background-color: aquamarine; */
    /* width: 640px;
    height: 1918px; */
    width: 350px;
    margin: 0 auto;
    position: relative;
}

.text-container{
    position: absolute;
    top:325px;
    text-align: center;
    /* background-color: red; */
    width: 100%;
}

/* .worker_ID_Image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 200px;
    position: relative;
    z-index: 1;
} */

.worker_name {
    margin-top: 30px;
    font-size: 30pt;
    font-weight: bolder;
    font-family: 'Noto Serif Khmer', serif;
}

.worker_position {
    font-size: 18pt;
    margin-top: -15px;
    font-weight: bolder;
    font-family: 'KhmerOSContent';
    color: #47423f;
}

.worker_id {
    font-size: 16pt;
    margin-top: 29px;
    font-weight:bold;
    font-family: 'KhmerOSContent', serif;
    color: white;
}

.worker_image {
    position:absolute;
    object-fit: cover;
    top: 102px;
    left: 70.8px;
    width: 208.5px;
    height: 243.5px;
    border-radius: 21px;
}