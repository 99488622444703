.acc-dashboard-card {
  padding: 10px 20px;
  background-color: purple;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  transition: all 0.3s;
}

.acc-dashboard-card:hover {
  transform: scale(0.98);
  cursor: pointer;
  opacity: 0.8;
}

.acc-dashboard-card:active {
  transform: scale(1.05);
  cursor: pointer;
  opacity: 0.5;
}

.acc-dashboard-card .acc-total {
  margin-top: 15px;
  font-size: 25px;
  color: white;
  font-weight: 600;
}

.acc-dashboard-card .acc-title {
  color: #ccc;
}

.acc-icon {
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 6px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
