.PersonalLabel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 20px;
}

.builderhistory {
    display: flex;
    align-content: center;
    font-size: medium;
}

.create-builder-upload-image {
    width: 200px;
    background-color: #7B7B7B !important;
    color: white !important;
}

.ant-form-item {
    margin-bottom: 10px !important;
}

.ant-upload-list-item-info {
    display: none !important;
}

.input-row-layout {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.Personal-Info-container {
    display: flex;

}

.worker-image {
    /* background-color: aliceblue; */
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /* padding-left: 40px; */
    padding-right: 40px;
    width: 50%;
    height: 100%;
}

.worker-info {
    /* background-color: rgb(79, 125, 165); */
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.worker-info-container {
    display: flex;
    align-items: center;
}

.work-label {
    width: 30%;
}

.work-input {
    margin-top: 5px;
    width: 69%;
    margin-left: 10px;
}

.worker-skill {
    /* background-color: aliceblue; */
    display: flex;
    flex-direction: column;
}

.worker-label {
    width: 100%;
}

.worker-skill-option {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.worker-skill-option-container {
    width: 50%;
    padding: 15px;
}

.worker-history-form {
    width: 100%;
    display: flex!important;
    justify-content: flex-end!important;
    padding: 20px 10px;
}

.worker-history-form-container {
    width: 50%;
    display: flex;
    align-items: ceRatnter;
    /* padding: 15px; */
}

.worker-history-row {
    width: 100%;
    display: flex;
    align-items: top;
    justify-content: flex-end;
}

.worker-history-input-date {
    width: 25%;
    margin-right: 10px;
    margin-top: 5px;
}

.worker-history-remove {
    width: 5%;
    padding: 10px;
}

.worker-history-input-salary {
    width: 25%;
    margin-left: 10px;
    margin-top: 5px;
    /* margin-top: -2px; */
}

.worker-history-input-other {
    width: 25%;
    margin-left: 10px;
    margin-top: 5px;
}


.add-worker-salary-History {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-top: 10px;
}

.worker-other-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.worker-other-lable {
    width: 25%;
    margin-right: 10px;
    text-align: right;
}

.worker-other-input {
    width: 50%;
    margin-left: 5px;
    margin-top: 5px;
}

.worker-fotter-submit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

}

.worker-fotter-submit-container {
    width: 50%;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 992px) {
    /* .Personal-Info-container {
        display: flex;
        flex-direction: column;
    }

    .worker-image {
        padding-left: 5px;
        padding-right: 5px;
        width: 100%;
        height: 100%;
    }

    .worker-info {
        width: 100%;
        height: 100%;
    }

    .worker-skill-option-container {
        width: 100%;
    }

    .worker-history-form-container {
        width: 100%;
    } */

    .worker-history-form {
        justify-content: flex-start;
    }

    /* .worker-history-input-date {
        width: 100%;
        margin-right: 2px;
    }

    .worker-history-input-salary {
        width: 49%;
        margin-left: 1px;
    }

    .worker-history-input-other {
        width: 50%;
        margin-left: 2px;
    }

    .worker-other-lable {
        width: 50%;
    }

    .worker-history-remove {
        width: 18%;
    } */
}