@import url(https://fonts.googleapis.com/css2?family=Bayon&family=Kantumruy:wght@300&family=Noto+Serif+Khmer:wght@100;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.site-layout {
  background-color: #ddd !important;
}

@font-face {
  font-family: "KhmerOSSiemreap";
  src: url(../../static/media/KHMEROSSIEMREAP.c7829629.TTF);
}

@font-face {
  font-family: "KhmerOSContent";
  src: url(../../static/media/KhmerOSContent.388079fa.ttf);
}

.khmer-os-siemreap {
  font-family: "KhmerOSSiemreap"!important;
}
.khmer-moul {
  font-family: "Moul"!important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  border-radius: 10px;
  background-color: #ddd;
}
/* ::-webkit-scrollbar-track{
  width: 10px;
  height: 8px;
  border-radius: 10px;
  background-color: #DDD;
} */
::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 10px;
}

.side-bar {
    font-family: 'KhmerOSSiemreap';
    font-weight: bold;
    /* font-size: 12pt !important; */
    color: white !important;
}
body, html {
 font-family: 'KhmerOSSiemreap' !important;
}
.site-card-wrapper{
    margin-top: 5px !important;
}

.ant-card-body{
    padding: 10px !important;
}

.ant-table-thead > tr > th{
    font-weight: bold !important;
}

.ant-page-header-heading-title{
    font-family: Moul;
    font-weight: normal !important;
    color: #1983e6 !important;
}
.Info-table{
    background: "#9cab18" !important
}
.create-request{
    position: relative;
}
.create-request button{
   position: absolute;
   right: 50px;
}

.ant-drawer-wrapper-body{
    background-color: rgb(180 217 255) !important;
}

.disabled-input-display{
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
    padding:6.5px 11px;
    font-size: 16px;
}

.login-form{
    width: 100%;
    height: 100vh!important;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  
  .Login-user-icon{
    width: 100%;
    font-size: 50pt;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    color: #1890ff;
  }

  .create_Btn{
      position: absolute;
      right: 0 !important;
  }

  .printIcon path {
      color: white !important;
      stroke: white !important;
  }

  .reqeustPrint{
    justify-content: center;
    align-items: center;
    
  }
  .display{
      display: none;
  }
  .displaying{
      display: block;
  }
  .table-customize tbody >tr > td{
    font-size: 16px!important;
    padding: 5px 8px ;
  }

  .table-customize thead >tr > th{
    font-size: 16px!important;
    padding: 10px 10px ;
  }

  .row-danger td{
      background-color: #FFDFD8 !important;
      /* color: white !important; */
  }
  @page {
    size: auto;
    margin: 1mm;
  }
  .image-card{
      object-fit: cover !important;
      height: 100px !important;
      /* width: 100%; */
      padding: 7px!important;
      cursor: pointer;
      border-radius: 13px 13px 0px 0px!important;
  }

  .image-card-mobile{
    object-fit: cover !important;
    height: 70px !important;
    width: 70px;
    /* padding-right: 5px!important; */
    cursor: pointer;
    border-radius: 50%;
}
  .ant-form-item-explain-error{
      margin-top: 5px !important;
      margin-bottom: 5px !important;
  }

  .card-report{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    /* padding: 10px!important; */
    /* max-height: 350px!important; */
    /* overflow-y: scroll; */
    height: 100%;
    transition: all .3s ease;
    overflow: hidden !important;
  }

  .card-report .ant-card-body{
    padding: 0px !important;
   
}

  .card-report:hover{
    transform: scale(1.03);
    z-index: 100;
    background: #ffe1ab;
  }

  /* .card-report .ant-card-body .i */

  .card-report-mobile{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    /* padding: 10px!important; */
    /* max-height: 350px!important; */
    /* overflow-y: scroll; */
    height: 100%;
    /* padding: 0px!important; */
  }

  .card-report-mobile:active{
      background-color: #ddd;
  }

  .drawer-phone .ant-drawer-wrapper-body{
      background-color: rgb(180 217 255)!important;
  }

  .loading-data{
      width: 100%;
      text-align: center;
  }

  .finished-row td, .finished-row td:hover{
      background-color: #ebebeb!important;
  }

  .requestTable tr td, .requestTable tr th{
      font-size: 16px!important;
  }

  .contentTag{
      overflow-x: scroll;
  }

  .contentTag::-webkit-scrollbar{
      width: 10px;
      height: 8px;
      border-radius: 10px;
      background-color: #DDD;
  }

  .contentTag::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 10px;

}

.customize-circle-progress .ant-progress-inner{ 
    width: 70px!important;
    height: 70px!important;
    font-size: 6px!important;
}

.modal-detail-card .ant-modal-body{
    padding: 5px!important;
    letter-spacing: -0.3px;
}

.weather__container {
    font-size: 16px;
    font-family:Arial, Helvetica, sans-serif !important;
    color: #f2f2f2;
    /* background-image: url(./assets/img/background.jpg); */
}

.success-bg{
    background: #deffea!important;
}
.Header {
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Header__title {
  text-align: left;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.wave-santa {
  position: absolute;
  left: -120px;
  bottom: -25px;
  transition: all 0.5s;
  opacity: 0;
}

.wave-santa:active {
  transform: scale(0.95);
}

.wave-santa.active-animate {
  left: 0;
  opacity: 1;
}

/* count down */
.react-countdown li:after{
  top: -2px!important;
  font-size: 20px !important;
  right: -11px;
}
.react-countdown .digit {
  font-size: 20px!important;
}
.react-countdown .text {
  font-size: 8px !important;
}

@media (max-width: 375px) {
  .Header {
    padding: 2rem;
  }
}

@media (max-width: 500px) {
  .wave-santa.active-animate {
    left: 75%;
  }
}

.Weather {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}

.Weather__info {
  text-align: left;
  display: flex;
}

.Weather__icon {
  width: 100px;
  height: 100px;
  animation-name: sun;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

@keyframes sun {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.Weather__list {
  font-size: 1rem;
  list-style: none;
  /* margin-left: 0.75rem; */
}

.list__temperature {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  transition: scale 0.3s, padding-left 0.3s;
}

.list__temperature:hover {
  scale: 1.5;
  padding-left: 0.75rem;
}

.list__temperature-symbol {
  font-weight: 500;
  font-size: 1rem;
}

.Weather__other-info {
  text-align: right;
  color: #ffff;
}

.other-info__city {
  font-size: 1.15rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #ffff;

}

.other-info__clouds {
  font-size: 1rem;
  color: #ffff;

}

.Weather {
  padding: 0 0 1.5rem 0;
}

.Forecast__title {
  font-size: 0.9rem;
  text-align: center;
  color: #ffff;
  font-weight: bold;
}

.Forecast__weather-icon {
  width: 60px;
  height: 60px;
}

.Forecast__weather-icon-now {
  width: 70px;
  height: 70px;
}

.Forecast__weather-icon:hover {
  animation-name: icons;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes icons {
  0% {
    position: relative;
    top: 0;
  }
  50% {
    position: relative;
    top: 0.25rem;
  }
  100% {
    position: relative;
    top: 0;
  }
}

.Forecast__temperature {
  display: flex;
  justify-content: center;
}

.temperature__max {
  margin-right: 0.2rem;
  color: #f37928;
}

.temperature__min {
  margin-left: 0.2rem;
}

.WeatherAndForecast {
  display: flex;
  flex-direction: column;
  color: #f2f2f2;
  padding: 2rem 3rem;
}

.WeatherAndForecast__container {
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media (max-width: 375px) {
  .WeatherAndForecast {
    padding: 2rem;
  }
}

.Warning {
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
  text-align: center;
}

.Warning > * {
  padding: 0.15rem 0;
}

.is-done-switch.ant-switch-checked {
    background-color: #32CD32;
}
.requestTable .ant-table-thead .ant-table-cell{
    background: #ddd!important;
}
@media print {
  .rm-print {
    display: none;
  }
}

.PersonalLabel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 20px;
}

.builderhistory {
    display: flex;
    align-content: center;
    font-size: medium;
}

.create-builder-upload-image {
    width: 200px;
    background-color: #7B7B7B !important;
    color: white !important;
}

.ant-form-item {
    margin-bottom: 10px !important;
}

.ant-upload-list-item-info {
    display: none !important;
}

.input-row-layout {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.Personal-Info-container {
    display: flex;

}

.worker-image {
    /* background-color: aliceblue; */
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /* padding-left: 40px; */
    padding-right: 40px;
    width: 50%;
    height: 100%;
}

.worker-info {
    /* background-color: rgb(79, 125, 165); */
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.worker-info-container {
    display: flex;
    align-items: center;
}

.work-label {
    width: 30%;
}

.work-input {
    margin-top: 5px;
    width: 69%;
    margin-left: 10px;
}

.worker-skill {
    /* background-color: aliceblue; */
    display: flex;
    flex-direction: column;
}

.worker-label {
    width: 100%;
}

.worker-skill-option {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.worker-skill-option-container {
    width: 50%;
    padding: 15px;
}

.worker-history-form {
    width: 100%;
    display: flex!important;
    justify-content: flex-end!important;
    padding: 20px 10px;
}

.worker-history-form-container {
    width: 50%;
    display: flex;
    align-items: ceRatnter;
    /* padding: 15px; */
}

.worker-history-row {
    width: 100%;
    display: flex;
    align-items: top;
    justify-content: flex-end;
}

.worker-history-input-date {
    width: 25%;
    margin-right: 10px;
    margin-top: 5px;
}

.worker-history-remove {
    width: 5%;
    padding: 10px;
}

.worker-history-input-salary {
    width: 25%;
    margin-left: 10px;
    margin-top: 5px;
    /* margin-top: -2px; */
}

.worker-history-input-other {
    width: 25%;
    margin-left: 10px;
    margin-top: 5px;
}


.add-worker-salary-History {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-top: 10px;
}

.worker-other-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.worker-other-lable {
    width: 25%;
    margin-right: 10px;
    text-align: right;
}

.worker-other-input {
    width: 50%;
    margin-left: 5px;
    margin-top: 5px;
}

.worker-fotter-submit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

}

.worker-fotter-submit-container {
    width: 50%;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 992px) {
    /* .Personal-Info-container {
        display: flex;
        flex-direction: column;
    }

    .worker-image {
        padding-left: 5px;
        padding-right: 5px;
        width: 100%;
        height: 100%;
    }

    .worker-info {
        width: 100%;
        height: 100%;
    }

    .worker-skill-option-container {
        width: 100%;
    }

    .worker-history-form-container {
        width: 100%;
    } */

    .worker-history-form {
        justify-content: flex-start;
    }

    /* .worker-history-input-date {
        width: 100%;
        margin-right: 2px;
    }

    .worker-history-input-salary {
        width: 49%;
        margin-left: 1px;
    }

    .worker-history-input-other {
        width: 50%;
        margin-left: 2px;
    }

    .worker-other-lable {
        width: 50%;
    }

    .worker-history-remove {
        width: 18%;
    } */
}
.print-body {
    /* background-color: aquamarine; */
    /* width: 640px;
    height: 1918px; */
    width: 350px;
    margin: 0 auto;
    position: relative;
}

.text-container{
    position: absolute;
    top:325px;
    text-align: center;
    /* background-color: red; */
    width: 100%;
}

/* .worker_ID_Image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 200px;
    position: relative;
    z-index: 1;
} */

.worker_name {
    margin-top: 30px;
    font-size: 30pt;
    font-weight: bolder;
    font-family: 'Noto Serif Khmer', serif;
}

.worker_position {
    font-size: 18pt;
    margin-top: -15px;
    font-weight: bolder;
    font-family: 'KhmerOSContent';
    color: #47423f;
}

.worker_id {
    font-size: 16pt;
    margin-top: 29px;
    font-weight:bold;
    font-family: 'KhmerOSContent', serif;
    color: white;
}

.worker_image {
    position:absolute;
    object-fit: cover;
    top: 102px;
    left: 70.8px;
    width: 208.5px;
    height: 243.5px;
    border-radius: 21px;
}
.print-report-container {
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  gap: 25px;
  background-color: white;
  padding: 25px;
  font-size: 11pt !important;
}
.print-report-container .teams {
  border-top: 5px solid #fff;
}

.print-report-container.loading-container {
  padding: 30px 10px;
}

/* .print-report-container .other-problem {
  font-size: 11pt !important;
  color: red;
}

.print-report-container .header-tmr-team {
  padding: 10px;
  font-weight: bold;
  background: #e6f7ff;
}

.print-report-container .row-tmr-team{
    padding: 5px 13px;
}

.header-row {
  display: flex;
  justify-content: space-between;
  background-color: #ddebf7;
  padding: 5px 10px;
  color: #305496;
}

.header-row.border-top {
  border-top: 2px solid #fff;
}

.header-summary {
  color: #808080;
}

.team-padding{
  padding: 5px 13px;
} */

.report-table-print {
  width: 100%;
  border: 1px solid #000;
}

.report-table-print .text-center {
  text-align: center;
}

.report-table-print .text-right {
  text-align: right;
}

.report-table-print .construction-name {
  font-weight: bold;
  font-size: 1rem;
}

.report-table-print .construction-date {
  font-weight: bold;
  background-color: #e4c8ad;
}

.report-table-print .row-header {
  background-color: #f0ebd0;
  font-weight: 600;
}

.report-table-print .space-between {
  display: flex;
  justify-content: space-between;
}

.report-table-print tr td {
  border: 1px solid #000;
  padding: 2px 6px;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    background-color: white !important;
    -webkit-print-color-adjust: exact;
  }

  .print-report-container {
    padding: 0px !important;
    display: flex !important;
  }
  /* .item {
    page-break-after: always;
  } */
}

@page {
  size: auto;
  /* margin: 10mm 10mm 25mm 10mm; */
  margin: 10mm 10mm;
}

.acc-dashboard-card {
  padding: 10px 20px;
  background-color: purple;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  transition: all 0.3s;
}

.acc-dashboard-card:hover {
  transform: scale(0.98);
  cursor: pointer;
  opacity: 0.8;
}

.acc-dashboard-card:active {
  transform: scale(1.05);
  cursor: pointer;
  opacity: 0.5;
}

.acc-dashboard-card .acc-total {
  margin-top: 15px;
  font-size: 25px;
  color: white;
  font-weight: 600;
}

.acc-dashboard-card .acc-title {
  color: #ccc;
}

.acc-icon {
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 6px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.print-expense-profit {
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  gap: 25px;
  background-color: white;
  padding: 25px;
  font-size: 11pt !important;
}
.print-expense-profit .teams {
  border-top: 5px solid #fff;
}

.print-expense-profit.loading-container {
  padding: 30px 10px;
}

.profit-expense-table-print {
  width: 100%;
  border: 1px solid #000;
}

.profit-expense-table-print .text-center {
  text-align: center;
}

.profit-expense-table-print .text-right {
  text-align: right;
}

.profit-expense-table-print .construction-name {
  font-weight: bold;
  font-size: 1rem;
}

.profit-expense-table-print .construction-date {
  font-weight: bold;
  background-color: #e4c8ad;
}

.profit-expense-table-print .row-header {
  background-color: #f0ebd0;
  font-weight: 600;
}

.profit-expense-table-print .space-between {
  display: flex;
  justify-content: space-between;
}

.profit-expense-table-print tr td {
  border: 1px solid #000;
  padding: 2px 6px;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    background-color: white !important;
    -webkit-print-color-adjust: exact;
  }

  .print-expense-profit {
    padding: 0px !important;
    display: flex !important;
  }
  /* .item {
      page-break-after: always;
    } */
}

@page {
  size: auto;
  /* margin: 10mm 10mm 25mm 10mm; */
  margin: 10mm 10mm;
}

