.Header {
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Header__title {
  text-align: left;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.wave-santa {
  position: absolute;
  left: -120px;
  bottom: -25px;
  transition: all 0.5s;
  opacity: 0;
}

.wave-santa:active {
  transform: scale(0.95);
}

.wave-santa.active-animate {
  left: 0;
  opacity: 1;
}

/* count down */
.react-countdown li:after{
  top: -2px!important;
  font-size: 20px !important;
  right: -11px;
}
.react-countdown .digit {
  font-size: 20px!important;
}
.react-countdown .text {
  font-size: 8px !important;
}

@media (max-width: 375px) {
  .Header {
    padding: 2rem;
  }
}

@media (max-width: 500px) {
  .wave-santa.active-animate {
    left: 75%;
  }
}
