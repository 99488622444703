.side-bar {
    font-family: 'KhmerOSSiemreap';
    font-weight: bold;
    /* font-size: 12pt !important; */
    color: white !important;
}
body, html {
 font-family: 'KhmerOSSiemreap' !important;
}
.site-card-wrapper{
    margin-top: 5px !important;
}

.ant-card-body{
    padding: 10px !important;
}

.ant-table-thead > tr > th{
    font-weight: bold !important;
}

.ant-page-header-heading-title{
    font-family: Moul;
    font-weight: normal !important;
    color: #1983e6 !important;
}
.Info-table{
    background: "#9cab18" !important
}
.create-request{
    position: relative;
}
.create-request button{
   position: absolute;
   right: 50px;
}

.ant-drawer-wrapper-body{
    background-color: rgb(180 217 255) !important;
}

.disabled-input-display{
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
    padding:6.5px 11px;
    font-size: 16px;
}

.login-form{
    width: 100%;
    height: 100vh!important;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  
  .Login-user-icon{
    width: 100%;
    font-size: 50pt;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    color: #1890ff;
  }

  .create_Btn{
      position: absolute;
      right: 0 !important;
  }

  .printIcon path {
      color: white !important;
      stroke: white !important;
  }

  .reqeustPrint{
    justify-content: center;
    align-items: center;
    
  }
  .display{
      display: none;
  }
  .displaying{
      display: block;
  }
  .table-customize tbody >tr > td{
    font-size: 16px!important;
    padding: 5px 8px ;
  }

  .table-customize thead >tr > th{
    font-size: 16px!important;
    padding: 10px 10px ;
  }

  .row-danger td{
      background-color: #FFDFD8 !important;
      /* color: white !important; */
  }
  @page {
    size: auto;
    margin: 1mm;
  }
  .image-card{
      object-fit: cover !important;
      height: 100px !important;
      /* width: 100%; */
      padding: 7px!important;
      cursor: pointer;
      border-radius: 13px 13px 0px 0px!important;
  }

  .image-card-mobile{
    object-fit: cover !important;
    height: 70px !important;
    width: 70px;
    /* padding-right: 5px!important; */
    cursor: pointer;
    border-radius: 50%;
}
  .ant-form-item-explain-error{
      margin-top: 5px !important;
      margin-bottom: 5px !important;
  }

  .card-report{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    /* padding: 10px!important; */
    /* max-height: 350px!important; */
    /* overflow-y: scroll; */
    height: 100%;
    transition: all .3s ease;
    overflow: hidden !important;
  }

  .card-report .ant-card-body{
    padding: 0px !important;
   
}

  .card-report:hover{
    transform: scale(1.03);
    z-index: 100;
    background: #ffe1ab;
  }

  /* .card-report .ant-card-body .i */

  .card-report-mobile{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    /* padding: 10px!important; */
    /* max-height: 350px!important; */
    /* overflow-y: scroll; */
    height: 100%;
    /* padding: 0px!important; */
  }

  .card-report-mobile:active{
      background-color: #ddd;
  }

  .drawer-phone .ant-drawer-wrapper-body{
      background-color: rgb(180 217 255)!important;
  }

  .loading-data{
      width: 100%;
      text-align: center;
  }

  .finished-row td, .finished-row td:hover{
      background-color: #ebebeb!important;
  }

  .requestTable tr td, .requestTable tr th{
      font-size: 16px!important;
  }

  .contentTag{
      overflow-x: scroll;
  }

  .contentTag::-webkit-scrollbar{
      width: 10px;
      height: 8px;
      border-radius: 10px;
      background-color: #DDD;
  }

  .contentTag::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 10px;

}

.customize-circle-progress .ant-progress-inner{ 
    width: 70px!important;
    height: 70px!important;
    font-size: 6px!important;
}

.modal-detail-card .ant-modal-body{
    padding: 5px!important;
    letter-spacing: -0.3px;
}

.weather__container {
    font-size: 16px;
    font-family:Arial, Helvetica, sans-serif !important;
    color: #f2f2f2;
    /* background-image: url(./assets/img/background.jpg); */
}

.success-bg{
    background: #deffea!important;
}