.print-report-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  background-color: white;
  padding: 25px;
  font-size: 11pt !important;
}
.print-report-container .teams {
  border-top: 5px solid #fff;
}

.print-report-container.loading-container {
  padding: 30px 10px;
}

/* .print-report-container .other-problem {
  font-size: 11pt !important;
  color: red;
}

.print-report-container .header-tmr-team {
  padding: 10px;
  font-weight: bold;
  background: #e6f7ff;
}

.print-report-container .row-tmr-team{
    padding: 5px 13px;
}

.header-row {
  display: flex;
  justify-content: space-between;
  background-color: #ddebf7;
  padding: 5px 10px;
  color: #305496;
}

.header-row.border-top {
  border-top: 2px solid #fff;
}

.header-summary {
  color: #808080;
}

.team-padding{
  padding: 5px 13px;
} */

.report-table-print {
  width: 100%;
  border: 1px solid #000;
}

.report-table-print .text-center {
  text-align: center;
}

.report-table-print .text-right {
  text-align: right;
}

.report-table-print .construction-name {
  font-weight: bold;
  font-size: 1rem;
}

.report-table-print .construction-date {
  font-weight: bold;
  background-color: #e4c8ad;
}

.report-table-print .row-header {
  background-color: #f0ebd0;
  font-weight: 600;
}

.report-table-print .space-between {
  display: flex;
  justify-content: space-between;
}

.report-table-print tr td {
  border: 1px solid #000;
  padding: 2px 6px;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    background-color: white !important;
    -webkit-print-color-adjust: exact;
  }

  .print-report-container {
    padding: 0px !important;
    display: flex !important;
  }
  /* .item {
    page-break-after: always;
  } */
}

@page {
  size: auto;
  /* margin: 10mm 10mm 25mm 10mm; */
  margin: 10mm 10mm;
}
