body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.site-layout {
  background-color: #ddd !important;
}

@font-face {
  font-family: "KhmerOSSiemreap";
  src: url("assets/fonts/KHMEROSSIEMREAP.TTF");
}

@font-face {
  font-family: "KhmerOSContent";
  src: url("assets/fonts/KhmerOSContent.ttf");
}

.khmer-os-siemreap {
  font-family: "KhmerOSSiemreap"!important;
}
.khmer-moul {
  font-family: "Moul"!important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  border-radius: 10px;
  background-color: #ddd;
}
/* ::-webkit-scrollbar-track{
  width: 10px;
  height: 8px;
  border-radius: 10px;
  background-color: #DDD;
} */
::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 10px;
}
