.print-expense-profit {
  display: flex;
  flex-direction: column;
  gap: 25px;
  background-color: white;
  padding: 25px;
  font-size: 11pt !important;
}
.print-expense-profit .teams {
  border-top: 5px solid #fff;
}

.print-expense-profit.loading-container {
  padding: 30px 10px;
}

.profit-expense-table-print {
  width: 100%;
  border: 1px solid #000;
}

.profit-expense-table-print .text-center {
  text-align: center;
}

.profit-expense-table-print .text-right {
  text-align: right;
}

.profit-expense-table-print .construction-name {
  font-weight: bold;
  font-size: 1rem;
}

.profit-expense-table-print .construction-date {
  font-weight: bold;
  background-color: #e4c8ad;
}

.profit-expense-table-print .row-header {
  background-color: #f0ebd0;
  font-weight: 600;
}

.profit-expense-table-print .space-between {
  display: flex;
  justify-content: space-between;
}

.profit-expense-table-print tr td {
  border: 1px solid #000;
  padding: 2px 6px;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    background-color: white !important;
    -webkit-print-color-adjust: exact;
  }

  .print-expense-profit {
    padding: 0px !important;
    display: flex !important;
  }
  /* .item {
      page-break-after: always;
    } */
}

@page {
  size: auto;
  /* margin: 10mm 10mm 25mm 10mm; */
  margin: 10mm 10mm;
}
